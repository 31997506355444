import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
//import {GoogleAnalytics} from '@awesome-cordova-plugins/google-analytics/ngx';
import {Capacitor} from '@capacitor/core';
// import {FirebaseAnalytics} from '@awesome-cordova-plugins/firebase-analytics/ngx';
declare let gtag;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  isNative = Capacitor.isNativePlatform();
  isGAReady = false;
  isFirebaseAnalytics = false;

  constructor(
    // private ga: GoogleAnalytics,
    // private firebaseAnalytics: FirebaseAnalytics
  ) {
    // if (this.isNative && !this.isFirebaseAnalytics) {
    //   this.ga.startTrackerWithId(environment.gaTrackerID)
    //     .then(() => {
    //       // console.log('Google analytics is ready now');
    //       this.isGAReady = true;
    //     })
    //     .catch(e => console.log('Error starting GoogleAnalytics', e));
    // }
  }

  trackView(title) {
    // if(!environment.trackAnalytics) {
    //   return false;
    // }
    // if(this.isNative) {
    //   if(this.isFirebaseAnalytics) {
    //     // this.firebaseAnalytics.logEvent('page_view', {page: title})
    //     //   .then((res: any) => console.log(res))
    //     //   .catch((error: any) => console.error(error));
    //   } else {
    //     if (this.isGAReady) {
    //       this.ga.trackView(title);
    //     } else {
    //       setTimeout(() => {
    //         this.trackView(title);
    //       }, 500);
    //     }
    //   }
    // } else {
    //   gtag('event', 'page_view', {
    //     // eslint-disable-next-line @typescript-eslint/naming-convention
    //     page_title: title,
    //     // eslint-disable-next-line @typescript-eslint/naming-convention
    //     page_path: window.location.pathname
    //   });
    // }
  }

  trackEvent(category, action, label?, value?) {
    if(!environment.trackAnalytics) {
      return false;
    }
    if (this.isNative) {
      if(this.isFirebaseAnalytics) {
        // this.firebaseAnalytics.logEvent('event',
        //   {
        //     action,
        //     // eslint-disable-next-line @typescript-eslint/naming-convention
        //     event_category: category,
        //     // eslint-disable-next-line @typescript-eslint/naming-convention
        //     event_label: label,
        //     value
        //   })
        //   .then((res: any) => console.log(res))
        //   .catch((error: any) => console.error(error));
      } else {
        // if (this.isGAReady) {
        //   this.ga.trackEvent(category, action, label, value);
        // } else {
        //   setTimeout(() => {
        //     this.trackEvent(category, action, label, value);
        //   }, 500);
        // }
      }
    } else {
      gtag('event', action, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_category: category,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_label: label,
        value
      });
    }
  }

  initGAScript() {
    if(!this.isNative) {
      const gtagScript: HTMLScriptElement = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackerID;
      document.head.prepend(gtagScript);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      gtag('config', environment.gaTrackerID, { send_page_view: false });
    }
  }
}
