import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthLocalService {

  authToken;
  apiPath = '';

  constructor(private http: HttpClient) {
    this.authToken = this.getUserToken();
    this.apiPath = environment.apiPath;
  }

  getUserToken() {
    return window.localStorage.getItem('auth0_token');
  }

  setUserToken(token) {
    this.authToken = token;
    window.localStorage.setItem('auth0_token', token);
    return true;
  }

  clearUserToken() {
    this.authToken = null;
    window.localStorage.removeItem('auth0_token');
  }

  verifyAccessToken() {
    const creds = {
      token: this.authToken
    };
    return this.http.post(
      this.apiPath + '/user/jwt/verify/',
      creds,
      {responseType: 'json', headers: this.getAuthHeaders( false, 'application/json')});
  }

  sendInviteCode(code) {
    const creds: FormData = new FormData();
    creds.append('invitation_code', code);
    return this.http.post(
      this.apiPath + '/user/auth0/organization/',
      creds,
      {responseType: 'json'});
  }

  getOrgByUUID(uuid) {
    return this.http.get(this.apiPath + '/organization/' + uuid + '/', {responseType: 'json'});
  }

  getUserProfile() {
    return this.http.get(this.apiPath + '/user/profile/', {responseType: 'json'});
  }

  setBackURL() {
    window.sessionStorage.setItem('back_url', window.location.href);
  }

  getBackURL() {
    return window.sessionStorage.getItem('back_url');
  }

  clearBackURL() {
    return window.sessionStorage.removeItem('back_url');
  }

  getAuthHeaders(auth = true, contentType = null) {
    /*if (auth && contentType) {
      return new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': contentType,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: this.getAuthHeaderAuth(),
      });
    } else if (auth) {
      return new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: this.getAuthHeaderAuth(),
      });
    } else if(contentType) {
      return new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': contentType,
      });
    }*/
    if(contentType) {
      return new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': contentType,
      });
    }
    return new HttpHeaders({});
  }

  getAuthHeadersV2(orgUUID, contentType = null) {
    if (orgUUID && contentType) {
      return new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': contentType,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'LJ-User-Organization': orgUUID,
      });
    } else if (orgUUID) {
      return new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'LJ-User-Organization': orgUUID,
      });
    } else if(contentType) {
      return new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': contentType,
      });
    }
    if(contentType) {
      return new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': contentType,
      });
    }
    return new HttpHeaders({});
  }

  saveVarToStorage(name, value) {
    window.localStorage.setItem(name, value);
  }

  getVarFromStorage(name) {
    return window.localStorage.getItem(name);
  }

  saveVarToSessionStorage(name, value) {
    window.sessionStorage.setItem(name, value);
  }

  getVarFromSessionStorage(name) {
    return window.sessionStorage.getItem(name);
  }
}
